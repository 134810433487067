var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{attrs:{"noContentPadding":"","closable":"","closeOnOutside":"","maxWidth":"950px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Storico Crediti ")]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('VsContainer',{attrs:{"variant":"narrow"}},[(_vm.loading)?_c('VsLoader',{attrs:{"overlay":""}}):_vm._e(),_c('div',{staticClass:"vs-flex md:vs-flex-nowrap vs-mb-6 vs-justify-between vs-flex-wrap vs-gap-4"},[_c('div',{staticClass:"vs-flex vs-items-center vs-gap-4"},[_c('VsFilterContainer',{attrs:{"countFilters":_vm.countFilters,"totalFound":_vm.$tc('manager.child.creditHistory.foundOperations', _vm.pagination.totalItems)},on:{"clear":function($event){_vm.filters = {
                        search: '',
                        type: null,
                    }}}},[_c('VsSelect',{attrs:{"placeholder":_vm.$t('manager.child.creditHistory.typePlaceholder'),"labelHidden":true,"clearable":true,"options":_vm.creditTypes},model:{value:(_vm.filters.type),callback:function ($$v) {_vm.$set(_vm.filters, "type", $$v)},expression:"filters.type"}}),_c('VsInput',{attrs:{"placeholder":_vm.$t('manager.child.creditHistory.searchPlaceholder'),"labelHidden":true,"debounce":500,"iconName":"search","clearable":true},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),(_vm.countFilters > 0)?_c('VsButton',{staticClass:"md:vs-hidden",attrs:{"variant":"link"},on:{"click":function($event){_vm.filters = {
                        search: '',
                        type: null,
                    }}}},[_vm._v(" "+_vm._s(_vm.$t('manager.child.creditHistory.clearFilters'))+" ")]):_vm._e(),_c('div',{staticClass:"vs-body-medium vs-flex-none"},[_vm._v(_vm._s(_vm.$tc('manager.child.creditHistory.foundOperations', _vm.pagination.totalItems)))])],1),_c('div',{staticClass:"vs-flex-initial vs-flex vs-items-center vs-gap-4"},[_c('VsSelect',{staticClass:"vs-hidden md:vs-inline-flex",staticStyle:{"max-width":"134px"},attrs:{"placeholder":_vm.$t('manager.child.creditHistory.orderByPlaceholder'),"labelHidden":true,"clearable":true,"options":_vm.creditsOrderBy},model:{value:(_vm.pagination.orderBy),callback:function ($$v) {_vm.$set(_vm.pagination, "orderBy", $$v)},expression:"pagination.orderBy"}}),_c('VsSelect',{staticClass:"md:vs-hidden",staticStyle:{"max-width":"116px"},attrs:{"placeholder":_vm.$t('manager.child.creditHistory.orderByPlaceholder'),"size":"small","labelHidden":true,"clearable":true,"options":_vm.creditsOrderBy},model:{value:(_vm.pagination.orderBy),callback:function ($$v) {_vm.$set(_vm.pagination, "orderBy", $$v)},expression:"pagination.orderBy"}})],1)]),(_vm.tableColumns.length > 0)?_c('VsTable',{staticClass:"vs-mb-6",attrs:{"headers":_vm.tableColumns,"items":_vm.credits,"selectTarget":"id","loading":_vm.loading},scopedSlots:_vm._u([{key:"col-operation",fn:function(ref){
                    var row = ref.row;
return [(row.operation)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"vs-flex vs-gap-2",attrs:{"content":row.operation}},[_c('VsBadge',{attrs:{"aspect":_vm.getOperationAspect(row.operation)}},[_vm._v(" "+_vm._s(_vm.$t(("manager.child.creditHistory.operation." + (row.operation))))+" ")])],1):_vm._e()]}},{key:"col-note",fn:function(ref){
                    var row = ref.row;
return [(row.note)?_c('div',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"vs-truncate",staticStyle:{"max-width":"250px"},attrs:{"content":row.note}},[_vm._v(" "+_vm._s(row.note)+" ")]):_vm._e()]}}],null,false,3538871537)}):_vm._e(),_c('VsPagination',{attrs:{"totalItems":_vm.pagination.totalItems,"expand":"","currentPage":_vm.pagination.currentPage,"itemsPerPage":_vm.pagination.itemsPerPage},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }