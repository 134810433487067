























































































































import { Component, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import { updateChild } from '@/api/userapi/manager/users'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'

@Component({
    name: 'VsEditChildModal',
})
export default class extends Vue {
    open = false
    loading = false

    private childData = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        company: '',
    }

    overviewChildrenData: any = null

    $refs!: any

    get user () {
        return UserModule.user
    }

    get specialChar () {
        return /^[A-Z0-9@&'".,\-àèìòù\s]*$/i
    }

    get childId () {
        return this.$route.params.childId
    }

    openModal (child: any) {
        this.childData = {
            firstname: child.firstname,
            lastname: child.lastname,
            email: child.email,
            phone: child.phone || '',
            company: child.company,
        }
        this.open = true
    }

    closeModal () {
        this.open = false
    }

    async updateChild () {
        const valid = await this.$refs.editChildForm.validate()
        if (!valid) return
        this.loading = true
        try {
            await updateChild(this.childId, { ...this.childData, phone: this.childData.phone || null })
            this.$root.$vsToast({
                heading: 'Account modificato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('child-changed')
            this.closeModal()
        } catch (e) {
            console.log(e)
            const errorCode = get(e, 'response.data.message', '')
            let message = ''
            if (errorCode === 'EmailNotValid') {
                this.$refs.createChildForm.setErrors({ childEmail: ['Indirizzo email già esistente oppure non valido'] })
            }
            if (errorCode === 'CannotCreateChild') {
                message = 'Non puoi modificare utenti di questa tipologia'
            }
            this.$root.$vsToast({
                heading: 'Errore durante la modifica dell\'account',
                message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }
}
