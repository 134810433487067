
































































































































import { getManagerChildCreditHistory } from '@/api/consoleApi/manager'
import { Component, Vue, Watch } from 'vue-property-decorator'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsTable, { TableHeader } from '@/components/VsTable/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFilterContainer from '@/components/VsFilterContainer/Index.vue'

@Component({
    name: 'VsChildCreditHistoryModal',
    components: {
        VsFullTopBarLayout,
        VsLoader,
        VsTable,
        VsContainer,
        VsFilterContainer,
    },
})
export default class extends Vue {
    open = false
    loading = false
    private credits: any[] = []
    private tableColumns: any[] = []

    private pagination: any = {
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0,
        orderBy: null,
    }

    private filters = {
        search: '',
        type: null,
    }

    get childId () {
        return this.$route.params.childId
    }

    get creditsOrderBy () {
        return [
            {
                label: this.$t('general.orderBy.createdAtDesc'),
                value: 'created_at|desc',
            },
            {
                label: this.$t('general.orderBy.createdAtAsc'),
                value: 'created_at|asc',
            },
        ]
    }

    get creditTypes () {
        return [
            {
                label: this.$t('manager.child.creditHistory.type.email'),
                value: 'email',
            },
            {
                label: this.$t('manager.child.creditHistory.type.sms'),
                value: 'sms',
            },
            {
                label: this.$t('manager.child.creditHistory.type.transactional'),
                value: 'senderplus',
            },
        ]
    }

    get countFilters () {
        let count = 0
        if (this.filters.search && this.filters.search.length > 0) count++
        if (this.filters.type) count++
        return count
    }

    openModal () {
        this.open = true
        this.getCreditHistory()
        this.getTableHeaders()
    }

    closeModal () {
        this.open = false
    }

    @Watch('filters', { deep: true, immediate: false })
    async clearPaginationAndGetData () {
        this.pagination.currentPage = 1
        this.getCreditHistory()
    }

    @Watch('pagination', { immediate: true, deep: true })
    async getCreditHistory () {
        this.loading = true
        try {
            const resp = await getManagerChildCreditHistory(this.childId, this.buildParams())
            this.credits = resp.data.data
            this.pagination.totalItems = resp.data.meta.pagination.total
        } catch (e) {
            this.credits = []
            this.pagination.totalItems = 0
        }
        this.loading = false
    }

    private buildParams () {
        const params: any = {
            orderBy: 'created_at',
            sortedBy: 'desc',
            page: this.pagination.currentPage,
            limit: this.pagination.itemsPerPage,
            searchJoin: 'and',
            search: this.buildSearch(),
            searchFields: this.buildSearchFields(),
        }

        if (this.pagination.orderBy) {
            const order = this.pagination.orderBy.split('|')
            params.orderBy = order[0]
            params.sortedBy = order[1]
        }

        return params
    }

    private buildSearch () {
        const search = [
            this.filters.search.trim() !== '' ? `note:${this.filters.search}` : '',
            this.filters.type ? `type:${this.filters.type}` : '',
        ].filter((el) => el !== '')

        return search.join(';')
    }

    private buildSearchFields () {
        const searchFields = [
            this.filters.search.trim() !== '' ? 'note:like' : '',
            this.filters.type ? 'type:=' : '',
        ].filter((el) => el !== '')

        return searchFields.join(';')
    }

    private getTableHeaders () {
        const tableColumns: TableHeader[] = []

        tableColumns.push(
            {
                id: 'type',
                key: 'type',
                label: 'Tipo',
                formatter: (value: any) => {
                    return this.$t(`manager.child.creditHistory.type.${value}`).toString()
                },
                visible: true,
            },
            {
                id: 'operation',
                key: 'operation',
                label: 'Operazione',
                formatter: (value: any) => {
                    return this.$t(`manager.child.creditHistory.operation.${value}`).toString()
                },
                visible: true,
            },
            {
                id: 'value',
                key: 'value',
                sortable: false,
                label: 'Crediti',
                formatter: (value: any) => {
                    return this.$options?.filters?.formatNumberWithThousandsSeparator(value)
                },
                visible: true,
            },
            {
                id: 'note',
                key: 'note',
                sortable: false,
                label: 'Note',
                visible: true,
            },
            {
                id: 'created_at',
                key: 'created_at',
                label: 'Data operazione',
                sortable: false,
                formatter: (value: any) => value === '0000-00-00 00:00:00' ? '' : this.$options?.filters?.formatDate(value),
                visible: true,
            },
        )

        this.tableColumns = tableColumns
    }

    private getOperationAspect (operation: string) {
        switch (operation) {
            case 'add': return 'success'
            case 'sub': return 'alert'
            default: return 'default'
        }
    }
}
