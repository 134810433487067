
































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import MyAccordion from '@/components/MyAccordion/Index.vue'
import VsSidebarLayout from '@/components/VsSidebarLayout/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsTabs from '@/components/VsTabs/Index.vue'
import VsPlanDetails from '@/modules/account/components/VsPlanDetails/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import VsListLine from '@/components/VsListLine/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsChildSidebar from '@/modules/manager/components/VsChildSidebar/Index.vue'
import VsChangePasswordModal from '@/modules/manager/components/VsChangePasswordModal/Index.vue'
import VsChildEplusRuleModal from '@/modules/manager/components/VsChildEplusRuleModal/Index.vue'
import VsManageCreditModal from '@/modules/manager/components/VsManageCreditModal/Index.vue'
import VsChildCreditHistoryModal from '@/modules/manager/components/VsChildCreditHistoryModal/Index.vue'
import { userStatusEnum } from '@/utils/users'
import { changeChildSupportRule, deleteChild, getAssignedEplusLicenses, getChildById, impersonateChild } from '@/api/userapi/manager/users'
import { getManagerChildCredits } from '@/api/consoleApi/manager'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'
import { isRecursive, userPlanEmailCreditsRateString } from '@/utils/rules'
import { UserModule } from '@/store/modules/user'
import { getUserCredits } from '@/api/consoleApi/user'

@Component({
    name: 'VsManagerChildIndex',
    components: {
        VsContainer,
        VsSectionHeader,
        VsDropdownButton,
        VsPlanDetails,
        VsSidebarLayout,
        VsTabs,
        VsSeparator,
        MyAccordion,
        VsChildSidebar,
        VsListLine,
        VsConfirm,
        VsChangePasswordModal,
        VsChildEplusRuleModal,
        VsManageCreditModal,
        VsChildCreditHistoryModal,
    },
})
export default class extends Vue {
    child: any = null
    consoleChild: any = null
    shopUser: any = null
    loading = false
    showDetails = false
    eplusLicense = {
        eplusValue: 0,
        eplusAssigned: 0,
    }

    childCredits: any = {
        email: 0,
        sms: 0,
        transactional: 0,
    }

    $refs!: any

    get childId () {
        return this.$route.params.childId
    }

    get userPlanEmailCreditsRateString () {
        return userPlanEmailCreditsRateString(this.child.configuration.rules)
    }

    get isRecursive () {
        if (!this.child) return false
        return isRecursive(this.child.configuration.rules)
    }

    get rechargeStrategyObjectValue () {
        if (!this.child) return 0
        return this.child.configuration.rules.emailRecursivity?.value || 0
    }

    get eplusLicenseRule () {
        if (!this.child) return 0
        return this.child.configuration.rules.eplusLicense
    }

    get supportRuleActive () {
        return this.child?.configuration.rules.support !== 'none'
    }

    get userCredits () {
        return UserModule.userCredits
    }

    mounted () {
        this.getAssignedEplusLicenses()
        this.updateUserCredits()
    }

    async getAssignedEplusLicenses () {
        try {
            const resp = await getAssignedEplusLicenses()
            this.eplusLicense = resp.data
        } catch (e) {
            this.eplusLicense = {
                eplusValue: 0,
                eplusAssigned: 0,
            }
            console.log(e)
        }
    }

    private parseCreditIconName (creditName: string) {
        switch (creditName) {
            case 'email':
                return 'mail'
            case 'sms':
                return 'chat'
            case 'transactional':
                return 'transactional'
        }
    }

    private parseCreditName (creditName: string) {
        switch (creditName) {
            case 'email': return 'Email'
            case 'sms': return 'Sms'
            case 'transactional': return this.$t('account.myPlan.transactional')
            default: return creditName
        }
    }

    private isEmailRecursive (creditName: any) {
        return this.isRecursive && creditName === 'email'
    }

    private hasUnlimitedEmail (creditName: any) {
        if (!this.child) return false
        return this.child.configuration.rules.emailUnlimited && creditName === 'email'
    }

    @Watch('childId', { immediate: true })
    async retrieveAllUserInformations () {
        this.loading = true
        await this.getChild()
        await this.getChildCredits()
        // await this.getShopUser()
        this.loading = false
    }

    async licenseUpdated () {
        this.loading = true
        await this.getChild()
        await this.getAssignedEplusLicenses()
        this.loading = false
    }

    async getChild () {
        try {
            const resp = await getChildById(this.childId)
            // TODO: eliminare questa riga quando le rules avranno l'interfaccia delle rulesV2 anche a backend
            resp.data.configuration.rules = resp.data.configuration.rulesV2 || resp.data.configuration.rules
            this.child = resp.data
        } catch (e) {
            console.log(e)
            this.child = null
            this.$root.$vsToast({
                heading: 'Utente non trovato',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            this.$router.replace({
                name: 'usersIndex',
            })
        }
    }

    async getChildCredits () {
        try {
            const resp = await getManagerChildCredits(this.childId)
            this.childCredits = resp.data.data
        } catch (e) {
            console.log(e)
            this.childCredits = {
                email: 0,
                sms: 0,
                transactional: 0,
            }
        }
    }

    // async getShopUser () {
    //     try {
    //         const resp = await getShopUserById(this.userId)
    //         this.shopUser = resp.data
    //     } catch (e) {
    //         console.log(e)
    //         this.shopUser = null
    //         this.$root.$vsToast({
    //             heading: 'Utente shop non trovato',
    //             timeout: 3000,
    //             aspect: VsToastAspectEnum.alert,
    //         })
    //     }
    // }

    async deleteUser () {
        try {
            await this.$refs.vsConfirmDeleteUser.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await deleteChild(this.childId)
            this.$root.$vsToast({
                heading: 'Account eliminato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$router.replace({
                name: 'managerIndex',
            })
        } catch (e) {
            console.log(e)
            let message = ''
            const error = get(e, 'response.data.message', '')
            if (error === 'UserIsNotDeletable') {
                message = 'Disabilita l\'utente prima di eliminarlo.'
            }
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione dell\'account',
                message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            this.loading = false
        }
    }

    get badgeStatusAspect () {
        if (!this.child) return 'subtle'
        switch (this.child.status) {
            case userStatusEnum.ENABLED: return 'success'
            case userStatusEnum.DISABLED: return 'alert'
            case userStatusEnum.PENDING_ACTIVATION: return 'warning'
            case userStatusEnum.PENDING_ENABLED:
            default: return 'subtle'
        }
    }

    async impersonateUser () {
        try {
            await impersonateChild(this.childId)
            window.location.href = '/user'
        } catch (e) {
            const code: string = get(e, 'response.data.message', '')
            let message = ''
            if (code === 'ChildNoPermission' || code === 'ManagerRuleNotAllowed') message = 'Non hai i permessi per accedere alla console dell\'account.'
            if (code === 'ChildNotEnabled') message = 'L\'account deve essere abilitato.'
            if (code === 'ManagerRuleNotAllowed') message = 'L\'account deve essere abilitato.'

            this.$root.$vsToast({
                heading: 'Impossibile accedere alla console',
                message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    async changeChildSupportRule (data: {enable: boolean}) {
        this.loading = true
        try {
            await changeChildSupportRule(this.childId, data)
            this.$root.$vsToast({
                heading: 'Supporto modificato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            await this.getChild()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la modifica del supporto',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    async openChangePasswordModal () {
        this.$refs.vsChangePasswordModal.openModal()
    }

    private async updateUserCredits () {
        try {
            const resp = await getUserCredits()
            const credits = resp.data
            UserModule.SET_USER_CREDITS(credits)
        } catch (e) {
            console.log(e)
        }
    }

    async creditsSaved () {
        this.getChildCredits()
        this.updateUserCredits()
    }
}
