var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"vs-flex vs-gap-2 vs-items-center"},[_c('div',[_vm._v(" Modifica account ")])])]},proxy:true},{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{attrs:{"disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.updateChild}},[_vm._v(" "+_vm._s(_vm.$t('general.save'))+" ")])]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('div',[_c('ValidationObserver',{ref:"editChildForm"},[_c('ValidationProvider',{attrs:{"name":'Nome',"vid":"childFirstname","rules":"required|max:60|min:3|alphaSpaces","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4",attrs:{"size":"large","max":60,"error":errors.length > 0,"errorMessage":errors[0],"label":'Nome'},model:{value:(_vm.childData.firstname),callback:function ($$v) {_vm.$set(_vm.childData, "firstname", $$v)},expression:"childData.firstname"}})]}}])}),_c('ValidationProvider',{attrs:{"vid":"childLastname","name":'Cognome',"rules":"required|max:60|min:3|alphaSpaces","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4",attrs:{"size":"large","max":60,"error":errors.length > 0,"errorMessage":errors[0],"label":'Cognome'},model:{value:(_vm.childData.lastname),callback:function ($$v) {_vm.$set(_vm.childData, "lastname", $$v)},expression:"childData.lastname"}})]}}])}),_c('ValidationProvider',{attrs:{"vid":"childEmail","name":'Email',"rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4",attrs:{"size":"large","error":errors.length > 0,"errorMessage":errors[0],"label":'Email'},model:{value:(_vm.childData.email),callback:function ($$v) {_vm.$set(_vm.childData, "email", $$v)},expression:"childData.email"}})]}}])}),_c('ValidationProvider',{attrs:{"vid":"mainPhone","name":"Telefono","slim":"","rules":"libphoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsPhoneInput',{staticClass:"vs-mb-4",attrs:{"label":"Telefono (opzionale)","size":"large","error":errors.length > 0,"errorMessage":errors[0],"clearable":""},model:{value:(_vm.childData.phone),callback:function ($$v) {_vm.$set(_vm.childData, "phone", $$v)},expression:"childData.phone"}})]}}])}),_c('ValidationProvider',{attrs:{"vid":"companyName","name":"Nome azienda","rules":{regex: _vm.specialChar}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4",attrs:{"label":"Nome azienda (opzionale)","type":"text","size":"large","name":"companyName","id":"companyName","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.childData.company),callback:function ($$v) {_vm.$set(_vm.childData, "company", $$v)},expression:"childData.company"}})]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }