






























































import { setManagerChildCredits } from '@/api/consoleApi/manager'
import { formatNumber } from '@/utils/formatter'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
    name: 'VsManageCreditModal',
})
export default class extends Vue {
    @Prop({
        required: true,
        default: () => [],
    })
    private currentCredits!: any[]

    private open = false
    private loading = false
    private creditType: 'email' | 'sms' | 'transactional' = 'email'
    private initCredits = 0
    private credits = 0
    private note = ''
    $refs!: any

    get creditsAvailable () {
        const credit = this.currentCredits.find(el => this.creditType === 'transactional' ? el.key === 'senderplus' : el.key === this.creditType)
        if (!credit) return 0
        return credit.value + this.initCredits
    }

    get creditsCaption () {
        return `Hai ancora ${formatNumber(this.creditsAvailable - this.credits)} crediti disponibili su ${formatNumber(this.creditsAvailable)}`
    }

    get childId () {
        return this.$route.params.childId
    }

    get parseCreditName () {
        switch (this.creditType) {
            case 'email': return 'Email'
            case 'sms': return 'Sms'
            case 'transactional': return this.$t('account.myPlan.transactional')
            default: return this.creditType
        }
    }

    openModal (creditType: 'email' | 'sms' | 'transactional', credits: number) {
        this.creditType = creditType
        this.initCredits = credits
        this.credits = credits
        this.note = ''
        this.open = true
    }

    private async saveCredits () {
        const valid = await this.$refs.manageCreditsObserver.validate()
        if (!valid) return
        this.loading = true
        try {
            const data = {
                value: this.credits,
                note: this.note,
            }
            await setManagerChildCredits(this.childId, this.creditType, data)
            this.$emit('credit-saved')
            this.$root.$vsToast({
                heading: 'Crediti salvati con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.open = false
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante il salvataggio dei crediti',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    closeModal () {
        this.open = false
    }
}

