































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsListLine from '@/components/VsListLine/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { DisabledReasonEnum } from '@/utils/users'
import { changeChildStatus, switchChildToExpert } from '@/api/userapi/manager/users'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsEditChildModal from '@/modules/manager/components/VsEditChildModal/Index.vue'

@Component({
    name: 'VsChildSidebar',
    components: {
        VsListLine,
        VsDropdownButton,
        VsEditChildModal,
        VsLoader,
        VsConfirm,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) child!: any
    loading = false

    $refs!: {
        vsConfirmDisableUser: VsConfirm
        vsUpgradeToExpertConfirm: VsConfirm
    }

    fakeDeliveryMetod = 'automatic'

    get childId () {
        return this.$route.params.childId
    }

    get parentType () {
        if (!this.child.parent?.relationType) return 'Indipendent'
        if (this.child.parent.relationType === 'starter') return 'Starter'
        if (this.child.parent.relationType === 'expert') return 'Expert'
        return 'Indipendent'
    }

    async changeStatus (childId: string, status: string) {
        try {
            this.loading = true
            const data = {
                status,
                disabledReason: status === 'enabled' ? '' : DisabledReasonEnum.DISABLED_BY_MANAGER,
                sendNotification: false,
            }
            await changeChildStatus(childId, data)
        } catch (e) {
            this.loading = false
        }
    }

    async disableUser () {
        try {
            await this.$refs.vsConfirmDisableUser.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await this.changeStatus(this.childId, 'disabled')
            this.$root.$vsToast({
                heading: 'Utente disabilitato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('child-changed')
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la disabilitazione dell\'utente',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async enableUser () {
        this.loading = true
        try {
            await this.changeStatus(this.childId, 'enabled')
            this.$root.$vsToast({
                heading: 'Utente abilitato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('child-changed')
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante l\'abilitazione dell\'utente',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async upgradeToExpert () {
        try {
            await this.$refs.vsUpgradeToExpertConfirm.openConfirm()
        } catch (e) {
            return
        }

        this.loading = true
        try {
            await switchChildToExpert(this.childId)

            this.$root.$vsToast({
                heading: 'Utente trasformato in Expert con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })

            this.$emit('child-changed')
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante il cambio di piano',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }
}
