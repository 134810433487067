










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsPlanDetailsRow',
})
export default class extends Vue {
    @Prop() label!: string
    @Prop() value?: string
}
