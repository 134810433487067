





































// import { getChangePasswordLink } from '@/api/userapi/users'
import { getChangePasswordLink } from '@/api/userapi/manager/users'
import { copyToClipboard } from '@/utils/copyToClipboard'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsChangePasswordModal',
})
export default class extends Vue {
    open = false
    loading = false
    link = ''

    get childId () {
        return this.$route.params.childId
    }

    openModal () {
        this.link = ''
        this.open = true
    }

    closeModal () {
        this.open = false
    }

    async changePasswordLink () {
        this.loading = true
        try {
            const resp = await getChangePasswordLink(this.childId)
            this.$root.$vsToast({
                heading: 'Link generato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.link = resp.data.url
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la generazione del link',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
        this.loading = false
    }

    private copyToClipboard () {
        try {
            copyToClipboard(this.link)
            this.$root.$vsToast({
                heading: this.$t('general.copyClip'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('general.copyClipError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }
}
