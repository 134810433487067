


















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsPlanDetailsRow from './VsPlanDetailsRow.vue'
import { PriceListPopulated, ShopUser } from '@/api/shop/types/shop'

@Component({
    name: 'VsPlanDetails',
    components: {
        VsPlanDetailsRow,
    },
})
export default class extends Vue {
    @Prop() rules!: any
    private shopUser: ShopUser | null = null
    private priceList: PriceListPopulated | null = null

    private valueInterpreter (value: any) {
        switch (value) {
            case true:
                return 'si'
            case false:
                return 'no'
            case -1:
                return 'illimitato/i'
            default:
                if (typeof value === 'string') {
                    return value
                } else if (Array.isArray(value)) {
                    if (value[0] && value[0] === '*') {
                        return 'tutti'
                    }
                    return value.length
                } else if (typeof value === 'object') {
                    let interval = ''
                    if (value.interval === 'days') {
                        interval = `${value.value === 1 ? ' giorno' : ' giorni'}`
                    } else if (value.interval === 'months') {
                        interval = `${value.value === 1 ? ' mese' : ' mesi'}`
                    } else if (value.interval === 'years') {
                        interval = `${value.value === 1 ? ' anno' : ' anni'}`
                    }
                    return `${value.value} ${interval}`
                }
                return value
        }
    }
}
