


























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'
import { changeChildEplusLicense } from '@/api/userapi/manager/users'
import { formatNumber } from '@/utils/formatter'

@Component({
    name: 'VsChildEplusRuleModal',
})
export default class extends Vue {
    @Prop({ required: false, default: () => { return { eplusValue: 0, eplusAssigned: 0 } } }) eplusLicense!: any
    open = false
    loading = false
    initLicenseNumber = 0
    licenseNumber = 0

    $refs!: any

    get user () {
        return UserModule.user
    }

    get licenseAvailable () {
        let limit = this.eplusLicense.eplusValue - this.eplusLicense.eplusAssigned + this.initLicenseNumber
        if (limit < 0) limit = 0
        return limit
    }

    get licenseNumberLimit () {
        return this.eplusLicense.eplusValue
    }

    get childId () {
        return this.$route.params.childId
    }

    get licenseCaption () {
        return `Hai ancora ${formatNumber(this.licenseAvailable - this.licenseNumber)} licenze disponibili su ${formatNumber(this.licenseNumberLimit)}`
    }

    openModal (licenseNumber: number) {
        this.initLicenseNumber = licenseNumber
        this.licenseNumber = licenseNumber
        this.open = true
    }

    closeModal () {
        this.open = false
    }

    async updateLicense () {
        const valid = await this.$refs.eplusChildForm.validate()
        if (!valid) return
        this.loading = true
        try {
            const response = await changeChildEplusLicense(
                this.childId,
                {
                    value: this.licenseNumber,
                },
            )
            this.$root.$vsToast({
                heading: 'Numero di licenze modificato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('rule-update', response.data)
            this.closeModal()
        } catch (e) {
            let message = ''
            const messageCode = get(e, 'response.data.message', '')
            if (messageCode === 'ParentEplusLicenseNotSufficient') {
                message = 'Licenze insufficienti'
            }
            this.$root.$vsToast({
                heading: 'Errore durante la modifica del numero di licenze',
                message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }
}
